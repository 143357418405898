import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import {
  authReduxConstants,
  orderReduxConstant,
  usersReduxConstants,
  returnsReduxConstant,
  categoryReduxConstant,
  productsReduxConstants,
  customersReduxConstants,
  dashboardReduxConstants,
  activityLogsReduxConstants,
  ratingReduxConstants,
} from "./constants";
import { Login } from "./pages/login/login";
import { adminRoutes, superAdminRoutes } from "./system/routes";
import Spinner from "./shared/components/spinner/spinner";
import DashboardRender from "./shared/components/dashboardRender/dashboardRender";
import "./assets/css/index.css";
import "./App.css";
import { getItemFromLocalStorage } from "./shared/utility";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";

const App = (props) => {
  const {
    spinnerActions,
    authReducer: { isAuthenticated },
  } = props;

  const userRole = getItemFromLocalStorage("userRole");
  const routes = userRole === "admin" ? adminRoutes : superAdminRoutes;

  const navigate = useNavigate();
  const location = useLocation();
  const query = useLocation().search;
  const params = new URLSearchParams(query);

  const token = params.get("token");

  const spinner = (key) => spinnerActions.includes(key);

  const isSpinnerRunning = () => {
    return (
      spinner(usersReduxConstants.GET_ALL_USERS_AS_ADMIN) ||
      spinner(usersReduxConstants.ADD_USER) ||
      spinner(usersReduxConstants.UPDATE_USER) ||
      spinner(usersReduxConstants.DELETE_USER) ||
      spinner(orderReduxConstant.GET_ALL_ORDERS) ||
      spinner(orderReduxConstant.UPDATE_ORDER) ||
      spinner(productsReduxConstants.GET_ALL_PRODUCTS) ||
      spinner(productsReduxConstants.ADD_PRODUCT) ||
      spinner(productsReduxConstants.UPDATE_PRODUCT) ||
      spinner(productsReduxConstants.DELET_PRODUCT) ||
      spinner(returnsReduxConstant.GET_ALL_RETURNS) ||
      spinner(returnsReduxConstant.UPDATE_RETURN) ||
      spinner(authReduxConstants.LOGOUT) ||
      spinner(categoryReduxConstant.CREATE_CATEGORY) ||
      spinner(categoryReduxConstant.UPDATE_CATEGORY) ||
      spinner(categoryReduxConstant.GET_ALL_CATEGORIES) ||
      spinner(categoryReduxConstant.DELETE_CATEGORY) ||
      spinner(customersReduxConstants.GET_ALL_CUSTOMERS) ||
      spinner(dashboardReduxConstants.GET_GOOGLE_ANALYTICS) ||
      spinner(activityLogsReduxConstants.GET_ALL_ACTIVITY_LOGS) ||
      spinner(ratingReduxConstants.GET_ALL_RATING) ||
      spinner(ratingReduxConstants.UPDATE_RATING)||
      spinner(usersReduxConstants.CHANGE_PASSWORD)
    );
  };

  useEffect(() => {
    if (!isAuthenticated) {
      if (location.pathname === "/password") {
        navigate(`/password?token=${token}`, { replace: true });
      } else {
        navigate("/login", { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      {isSpinnerRunning() && <Spinner />}
      {isAuthenticated ? (
        <Routes>
          <>
            {routes.map((link) => (
              <Route
                key={link.path}
                path={link.path}
                exact
                element={
                  <DashboardRender>
                    <link.component />
                  </DashboardRender>
                }
              />
            ))}
          </>
        </Routes>
      ) : (
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/login" exact element={<Login />} />
          <Route path={"/password"} exact element={<ForgotPassword />} />
        </Routes>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  spinnerActions: state.systemReducer.spinnerActions,
});

export default connect(mapStateToProps)(App);
