import React, { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faKey, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import Header from "../../shared/components/header/header";
import { convertToReadableDate } from "../../shared/utility";
import { userActions } from "../../redux/actions/admins/users.action";
import ReactTable from "../../shared/components/reactTable/reactTable";
import useModalDisplay from "../../shared/hooks/useModalDisplay";
import { userPageFilters } from "../../constants";
import {
  getAddUserModal,
  openChangePasswordModal,
  openConfirmationModal,
} from "../../shared";

const Users = () => {
  //Getting state and action from redux
  const dispatch = useDispatch();
  const { allUsers, usersPagination } = useSelector(
    (state) => state.usersReducer
  );
  const { user } = useSelector((state) => state.authReducer);

  const [filter, setFilter] = useState({});
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState([{ id: "updatedAt", desc: true }]);

  const [showModal, closeModal, modalElement, setComponent] = useModalDisplay();

  const params = {
    page: usersPagination.pageNumber,
    limit: usersPagination.pageSize,
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "username",
        header: () => "Name",
      },
      {
        accessorKey: "email",
        header: () => "Email",
      },
      {
        accessorKey: "role",
        header: () => "Role",
      },
      {
        accessorKey: "createdAt",
        header: () => "Created At",
        cell: (row) => convertToReadableDate(row.getValue()),
      },
      {
        accessorKey: "updatedAt",
        header: () => "Updated At",
        cell: (row) => convertToReadableDate(row.getValue()),
      },
      {
        accessorKey: "status",
        header: () => "Status",
        cell: (row) => {
          const columnValue = row.getValue();
          return (
            <>
              {columnValue === "active" ? (
                <span className="text-green-600">Active</span>
              ) : (
                <span className="text-red-600 ">In-Active</span>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "action",
        header: () => "Actions",
        cell: ({ row: { original } }) => {
          return (
            <>
              <FontAwesomeIcon
                icon={faEdit}
                className={
                  original.role === "superAdmin"
                    ? "opacity-20"
                    : "cursor-pointer"
                }
                onClick={() => {
                  if (original.role !== "superAdmin") {
                    editUserDetails(original);
                  }
                }}
              />
              <FontAwesomeIcon
                icon={faKey}
                type="button"
                className="mx-2"
                onClick={() => changeUserPassword(original)}
              />
              <FontAwesomeIcon
                color="red"
                className={
                  user.id === original._id ? "opacity-20" : "cursor-pointer   "
                }
                icon={faTrashAlt}
                onClick={() => {
                  if (user.id !== original._id) {
                    removeUser(original);
                  }
                }}
              />
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getData = (pageNumber) => {
    dispatch(
      userActions.getAllUsersAsAdmin({
        page: pageNumber,
        limit: 10,
        searchQuery: search,
        status: filter,
        ...(sorting.length > 0 && {
          sortBy: sorting[0].desc ? "dsc" : "asc",
          sortField: sorting[0].id,
        }),
      })
    );
  };

  const removeUser = (user) => {
    setComponent(
      openConfirmationModal({
        title: "Delete User Confirmation",
        description: `Are you sure to delete ${user.username}?`,
        closeModal,
        onConfirm: () => {
          dispatch(userActions.deleteUser(user._id, params, closeModal));
        },
      })
    );
  };

  const changeUserPassword = (user) => {
    setComponent(
      openChangePasswordModal({
        title: "Change Password",
        closeModal,
        onConfirm: (data, reset) => {
          dispatch(
            userActions.changePassword(
              {
                userId: user._id,
                currentPassword: data.currentPassword,
                newPassword: data.newPassword,
              },
              params,
              () => {
                reset();
                closeModal();
              }
            )
          );
        },
      })
    );
  };

  const editUserDetails = (user) => {
    setComponent(
      getAddUserModal({
        title: "Edit Admin",
        onClose: closeModal,
        onConfirm: (data, reset) => {
          dispatch(
            userActions.updateUser(user._id, data, params, () => {
              reset();
              closeModal();
            })
          );
        },
        data: user,
        isEdit: true,
      })
    );
  };

  const onFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const onSort = (newSorting) => {
    setSorting(newSorting);
  };

  const onPageChange = (pageNo) => {
    getData(pageNo);
  };

  const addNewAdmin = (data, reset) => {
    dispatch(
      userActions.addNewUser(
        { ...data, firstName: "test", lastName: "test", address: "test" },
        params,
        () => {
          reset();
          closeModal();
        }
      )
    );
  };

  const openAddUserModal = () => {
    setComponent(
      getAddUserModal({
        title: "Add Admin",
        onClose: closeModal,
        onConfirm: addNewAdmin,
      })
    );
  };

  useEffect(() => {
    getData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filter, sorting[0]?.id, sorting[0]?.desc]);

  return (
    <Container fluid>
      {showModal && modalElement}
      <Header
        title="Admins"
        search={search}
        buttonName="+ Add"
        filter={filter}
        setOnSearch={setSearch}
        filters={userPageFilters}
        setFilter={onFilterChange}
        buttonOnClick={openAddUserModal}
      />
      <div className="h-full bg-white shadow p-3 rounded">
        <ReactTable
          columns={columns}
          data={allUsers || []}
          onSort={onSort}
          sortBy={sorting}
          onPageChange={onPageChange}
          rowsPerPage={usersPagination.pageSize}
          pageNumber={usersPagination.pageNumber}
          totalRows={usersPagination.totalElements}
        />
      </div>
    </Container>
  );
};

export default Users;
