import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { productsReduxConstants, sizesArray } from "../../../constants";
import { Col, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap";
import { categoriesActions } from "../../../redux/actions";
import ColorSelector from "../colorSelector/colorSelector";
import ReactSelect from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CustomOptions, CustomValueContainer } from "../../utility";

import axios from "axios";
import { toast } from "react-toastify";

const AddProductModal = ({ title, onClose, onConfirm, data }) => {
  const [mediaFiles, setMediaFiles] = useState(data?.images || []);
  const [mainCategory, setMainCategory] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const spinnerActions = useSelector(
    (state) => state.systemReducer.spinnerActions
  );
  const allCategories = useSelector(
    (state) => state.categoryReducer.allCategories
  );

  const isSpinnerRunning = () => {
    return (
      spinnerActions.includes(productsReduxConstants.ADD_PRODUCT) ||
      spinnerActions.includes(productsReduxConstants.UPDATE_PRODUCT)
    );
  };

  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: data,
    disabled: isSpinnerRunning(),
  });

  const onSubmit = (data) => {
    onConfirm(data, reset);
  };

  const closeModal = () => {
    reset();
    onClose();
  };

  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;
    const selectedCategory = allCategories.find(
      (category) => category._id === selectedCategoryId
    );
    setMainCategory(selectedCategory);
    setValue("parentCategoryId", selectedCategoryId);
  };

  const handleMediaChange = async (event) => {
    setLoading(true);
    const files = Array.from(event.target.files);
    const validFiles = [];

    for (const file of files) {
      if (!file.type.startsWith("image/") && !file.type.startsWith("video/")) {
        alert("Invalid file type. Please upload images or videos only.");
        continue;
      }

      if (file.size > 5 * 1024 * 1024) {
        // 5 MB limit
        alert(`File ${file.name} is too large. Maximum size allowed is 5MB.`);
        continue;
      }

      validFiles.push(file);
    }

    const uploadToServer = async (file) => {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/cloudinary/upload`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        return response.data.url;
      } catch (error) {
        console.error("Error uploading to server:", error);
        toast.error("Error uploading to server");
        return null;
      }
    };

    const uploadedFiles = await Promise.all(validFiles.map(uploadToServer));
    const successfulUploads = uploadedFiles.filter(Boolean);

    setMediaFiles((prevFiles) => [...prevFiles, ...successfulUploads]);

    // Save URLs to the form data using setValue
    const updatedImages = [...mediaFiles, ...successfulUploads];
    setValue(
      "images",
      updatedImages.map((file) => file)
    );
    setLoading(false);
  };

  const removeMediaFile = async (index) => {
    const newFiles = mediaFiles.filter((_, i) => i !== index);
    setMediaFiles(newFiles);

    // Update the form data with the remaining URLs
    setValue(
      "images",
      newFiles.map((file) => file.url)
    );
  };

  useEffect(() => {
    dispatch(categoriesActions.getAllCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.category && allCategories.length) {
      const productCategory = allCategories.find(
        (categ) => categ._id === data.category
      );
      setMainCategory(productCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.category]);

  return (
    <Modal show={true} onHide={closeModal} size="xl">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title className="font-bold">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-3">
            <Row>
              <Col md={6}>
                <FloatingLabel
                  controlId="floatingProductName"
                  label="Product Name"
                >
                  <Form.Control
                    type="text"
                    name="productName"
                    className="h-12 rounded-md"
                    placeholder="Product Name"
                    {...register("productName", { required: true })}
                    isInvalid={errors.productName}
                  />
                </FloatingLabel>
              </Col>
              <Col md={6}>
                <FloatingLabel
                  controlId="shortDescription"
                  label="Short Description"
                >
                  <Form.Control
                    type="text"
                    as="textarea"
                    name="shortDescription"
                    className="h-12 rounded-md"
                    placeholder="Short Description"
                    {...register("shortDescription", { required: true })}
                    isInvalid={errors.shortDescription}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <FloatingLabel controlId="longDescription" label="Long Description">
              <Form.Control
                type="text"
                as="textarea"
                name="longDescription"
                className="h-12 rounded-md"
                placeholder="Long Description"
                {...register("longDescription", { required: true })}
                isInvalid={errors.longDescription}
              />
            </FloatingLabel>

            <Row className="g-1">
              <Col>
                <FloatingLabel controlId="brandName" label="Brand Name">
                  <Form.Control
                    type="text"
                    name="brand"
                    placeholder="Brand Name"
                    className="h-12 rounded-md"
                    {...register("brand", { required: true })}
                    isInvalid={errors.brand}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="g-1">
              <Col>
                <FloatingLabel controlId="supplierName" label="Supplier Name">
                  <Form.Control
                    type="text"
                    name="supplier.name"
                    placeholder="Supplier Name"
                    className="h-12 rounded-md"
                    {...register("supplier.name", { required: true })}
                    isInvalid={errors.supplier?.name}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="about supplier"
                  label="About Supplier"
                >
                  <Form.Control
                    type="text"
                    name="supplier.about"
                    placeholder="About Supplier"
                    className="h-12 rounded-md"
                    {...register("supplier.about")}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="supplierLink" label="Supplier Link">
                  <Form.Control
                    type="text"
                    name="supplier.supplier_link"
                    placeholder="Supplier Link"
                    className="h-12 rounded-md"
                    {...register("supplier.supplier_link")}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="g-1">
              <Col>
                <FloatingLabel controlId="category" label="Main Category">
                  <Form.Select
                    name="category"
                    className="h-12 rounded-md"
                    {...register("category", {
                      required: "select main category",
                      onChange: handleCategoryChange,
                    })}
                    isInvalid={errors.category}
                  >
                    <option value="">Select main category</option>
                    {allCategories.map((category) => (
                      <option value={category._id}>
                        {category.categoryName}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              {mainCategory?.subcategories &&
                mainCategory.subcategories.length > 0 && (
                  <Col>
                    <FloatingLabel controlId="subCategory" label="Sub Category">
                      <Form.Select
                        name="subCategory"
                        className="h-12 rounded-md"
                        {...register("subCategory", {
                          required: "select sub category",
                        })}
                        isInvalid={errors.subCategory}
                      >
                        <option value="">Select Sub category</option>
                        {mainCategory?.subcategories.map((category) => (
                          <option value={category._id}>
                            {category.categoryName}
                          </option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                )}
            </Row>
            <Row className="g-1">
              <Col>
                <label>Sizes:</label>
                <Controller
                  name="sizes"
                  control={control}
                  defaultValue={[]}
                  rules={{ required: "At least one size must be selected" }}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      id="sizes"
                      isMulti
                      placeholder="select size"
                      options={sizesArray}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      getOptionValue={(option) => option.value}
                      getOptionLabel={(option) => option.name}
                      classNamePrefix="multi-color-dropdown"
                      components={{
                        Option: CustomOptions,
                        ValueContainer: CustomValueContainer,
                      }}
                      styles={{
                        option: (styles) => ({
                          ...styles,
                          backgroundColor: "white",
                          color: "black",
                        }),
                      }}
                    />
                  )}
                />
                {errors.sizes && (
                  <small className="text-red-500">{errors.sizes.message}</small>
                )}
              </Col>
              <Col>
                <label>Colors:</label>
                <ColorSelector errors={errors} control={control} />
              </Col>
            </Row>
            <Row className="g-1">
              <Col md={4}>
                <FloatingLabel controlId="price" label="Price">
                  <Form.Control
                    step="0.01"
                    name="price"
                    type="number"
                    placeholder="Price"
                    className="h-12 rounded-md"
                    {...register("price", {
                      required: true,
                      min: 0,
                      validate: (value) => {
                        const regex = /^\d+(\.\d{1,2})?$/;
                        return (
                          regex.test(value) ||
                          "Maximum of two decimal places allowed"
                        );
                      },
                    })}
                    isInvalid={errors.price}
                  />
                </FloatingLabel>
              </Col>
              <Col md={4}>
                <FloatingLabel controlId="discountPrice" label="Discount Price">
                  <Form.Control
                    type="number"
                    step="0.01"
                    name="discountPrice"
                    placeholder="Discount Price"
                    className="h-12 rounded-md"
                    {...register("discountPrice", {
                      min: 0,
                      validate: (value) => {
                        const regex = /^\d+(\.\d{1,2})?$/;
                        return (
                          regex.test(value) ||
                          "Maximum of two decimal places allowed"
                        );
                      },
                    })}
                  />
                </FloatingLabel>
              </Col>
              <Col md={4}>
                <FloatingLabel controlId="totalStock" label="Total Stock">
                  <Form.Control
                    type="number"
                    name="stock"
                    placeholder="Total Stock"
                    className="h-12 rounded-md"
                    {...register("stock", { required: true, min: 0 })}
                    isInvalid={errors.stock}
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12}>
                <FloatingLabel
                  controlId="admin_private_note"
                  label="Admin Notes"
                >
                  <Form.Control
                    type="text"
                    as="textarea"
                    cols={4}
                    name="admin_private_note"
                    placeholder="Admin notes"
                    className="h-12 rounded-md"
                    {...register("admin_private_note", {
                      min: 0,
                    })}
                    isInvalid={errors.admin_private_note}
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <Form.Group
              className={`bg-slate-200 p-2 rounded-md ${
                errors.images && "border-red-500 border-1"
              }`}
            >
              <Form.Label
                htmlFor="media"
                className={`block text-sm font-medium system-bg-color w-fit text-white py-2 px-3 rounded-md mx-auto my-auto cursor-pointer `}
              >
                Upload Images/Videos
              </Form.Label>
              <Form.Control
                multiple
                id="media"
                type="file"
                accept="image/*,video/*"
                isInvalid={errors.images}
                {...register("images", {
                  required: mediaFiles.length === 0,
                  onChange: handleMediaChange,
                })}
                className="w-full  rounded-md cursor-pointer focus:outline-none hidden"
              />
              <div className="flex flex-wrap mt-2 items-center">
                {loading ? (
                  <div className="flex items-center justify-center w-full h-full">
                    <Spinner />
                  </div>
                ) : (
                  mediaFiles.map((file, index) => (
                    <div key={index} className="w-15 h-15 m-1 relative group">
                      {file?.endsWith(".mp4") ? (
                        <video
                          src={file}
                          className="object-cover rounded"
                          controls
                          height={"auto"}
                          width={200}
                        />
                      ) : (
                        <img
                          src={file}
                          alt="upload"
                          height={"auto"}
                          width={200}
                          className="object-cover rounded"
                        />
                      )}

                      <button
                        type="button"
                        className="absolute top-0 right-0"
                        onClick={() => removeMediaFile(index)}
                      >
                        <FontAwesomeIcon icon={faTimes} color="red" />
                      </button>
                    </div>
                  ))
                )}
              </div>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-center md:w-2/4 gap-2">
            <button
              type="button"
              onClick={closeModal}
              disabled={isSpinnerRunning()}
              className="border-2 border-red-600 w-full  h-12  rounded text-red-600 hover:text-white hover:bg-red-600 disabled:bg-slate-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSpinnerRunning()}
              className="bg-[#29D30A] w-full h-12 text-white rounded disabled:bg-[#bcfab1]"
            >
              {data ? "Update" : "Add"}
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddProductModal;
