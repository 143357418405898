import { format } from "date-fns";
import { Form } from "react-bootstrap";
import { components } from "react-select";

export const successAction = (type, data) => ({
  type: `${type}_SUCCESS`,
  payload: data,
});

export const failureAction = (type, error) => ({
  type: `${type}_FAILURE`,
  payload: error,
});

export const successReducerType = (type) => `${type}_SUCCESS`;
export const failureReducerType = (type) => `${type}_FAILURE`;

export const setItemTolocalStorage = (key, value) => {
  if (typeof window !== "undefined") return localStorage.setItem(key, value);
};

export const getItemFromLocalStorage = (key) => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(key);
  }
};

export const removeItemFromLocalStorage = (key) => {
  if (typeof window !== "undefined") return localStorage.removeItem(key);
};

export const setItemToSessionStorage = (key, value) => {
  if (typeof window !== "undefined") return sessionStorage.setItem(key, value);
};

export const getItemFromSessionStorage = (key) => {
  if (typeof window !== "undefined") return sessionStorage.getItem(key);
};

export const removeItemFromSessionStorage = (key) => {
  if (typeof window !== "undefined") return sessionStorage.removeItem(key);
};

export const convertToReadableDate = (timestamp) => {
  return format(new Date(timestamp), "dd-MM-yyyy");
};

export const convertToReadableDateWithTime = (timestamp) => {
  return format(new Date(timestamp), "PPpp");
};

// Custom option component to include a checkbox
export const CustomOptionForColors = (props) => {
  return (
    <components.Option {...props}>
      <Form.Group className="flex items-center justify-between h-10">
        <Form.Check checked={props.isSelected} onChange={() => null} />
        <Form.Label
          className="p-3 rounded-full w-3"
          style={{ backgroundColor: props.data.value }}
        ></Form.Label>
      </Form.Group>
    </components.Option>
  );
};

export const CustomValueContainer = ({ children, ...props }) => {
  const { getValue } = props;

  const selectedOptions = getValue();
  const count = selectedOptions.length;

  return count > 2 ? (
    <components.ValueContainer {...props}>
      Selected {count} {props.selectProps.name}
    </components.ValueContainer>
  ) : (
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  );
};

// Custom option component to include a checkbox
export const CustomOptions = (props) => {
  return (
    <components.Option {...props}>
      <Form.Group className="flex items-center justify-between h-10">
        <Form.Check checked={props.isSelected} onChange={() => null} />
        <Form.Label className="p-3 rounded-full w-3">
          {props.data.value}
        </Form.Label>
      </Form.Group>
    </components.Option>
  );
};

// Custom date formatter
export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

//Formate currency
export const formateCurreny = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(9) + "B"; // Convert to billions
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(6) + "M"; // Convert to millions
  } else if (num >= 1000) {
    return (num / 1000).toFixed(3) + "k"; // Convert to thousands
  } else {
    return num.toString(); // Return the number itself if it's less than 1000
  }
};
