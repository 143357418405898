export const productsReduxConstants = {
  ADD_PRODUCT: "ADD_PRODUCT",
  DELET_PRODUCT: "DELET_PRODUCT",
  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  GET_ALL_PRODUCTS: "GET_ALL_PRODUCTS",
  GET_ALL_TOP_RATED_PRODUCTS: "GET_ALL_TOP_RATED_PRODUCTS",
  UPLOAD_PRODUCTS_IN_BULK: "UPLOAD_PRODUCTS_IN_BULK",
};
export const sizesArray = [
  { name: "6", value: "6" },
  { name: "8", value: "8" },
  { name: "12", value: "12" },
  { name: "14", value: "14" },
  { name: "16", value: "16" },
  { name: "18", value: "18" },
  { name: "20", value: "20" },
  { name: "22", value: "22" },
  { name: "24", value: "24" },
];
